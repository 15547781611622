import React, { useState } from 'react';
import JSZip from "jszip";
import { Key } from "./Key";
import AutoPromptPicker from './AutoPromptPicker';
import { compressImage, sendSlackMessage } from './utils/utility';
import { getSlackMessagePayload } from './utils/slack';

const quality = 0.96;
const imageWidth = 394;
const imageHeight = 221;

const Ideogram = () => {
  const [bulkPrompts, setBulkPrompts] = useState('');
  const [prompts, setPrompts] = useState([]);
  const [generatedImages, setGeneratedImages] = useState([]);
  const [loading, setLoading] = useState([]);
  const [error, setError] = useState(null);

  // API key (in a real app, this should be secured)
  const API_KEY = Key;

  const generateImage = async (prompt, index, isRegenerateImage = false) => {
    try {
      setLoading(prev => {
        const newLoading = [...prev];
        newLoading[index] = true;
        return newLoading;
      });
      setError(null);
  
      const response = await fetch('https://api.ideogram.ai/generate', {
        method: 'POST',
        headers: {
          'Api-Key': API_KEY,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          image_request: {
            prompt,
            aspect_ratio: 'ASPECT_16_9',
            model: 'V_2_TURBO',
            magic_prompt_option: 'OFF',
            style_type: "GENERAL"
          }
        })
      });
  
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
  
      const data = await response.json();
      
      // Access the URL from the correct path in the response
      if (data.data && data.data[0] && data.data[0].url) {
        setGeneratedImages(prev => {
          const newImages = [...prev];
          newImages[index] = data.data[0].url;  // Changed this line to access correct URL path
          return newImages;
        });
        if (isRegenerateImage) {
          // slack
          const info = `*Data:*\n${prompt}`;
          const slackData = getSlackMessagePayload(
            isRegenerateImage,
            info,
            prompts.length
          );
          await sendSlackMessage(slackData);
        }
      } else {
        throw new Error('No image URL in response');
      }
    } catch (err) {
      setError(`Error generating image: ${err.message}`);
    } finally {
      setLoading(prev => {
        const newLoading = [...prev];
        newLoading[index] = false;
        return newLoading;
      });
    }
  };

  const generateAllImages = async () => {
    if (prompts.length === 0) {
      setError("Please add prompts first");
      return;
    }
    
    // Initialize arrays for new batch
    setGeneratedImages(new Array(prompts.length).fill(null));
    setLoading(new Array(prompts.length).fill(false));
    
    // Generate images in batches of 5
    for (let i = 0; i < prompts.length; i += 5) {
      const batchPrompts = prompts.slice(i, i + 5);
      const batchPromises = batchPrompts.map((prompt, batchIndex) => 
        generateImage(prompt, i + batchIndex)
      );
      await Promise.all(batchPromises);
    }
    // slack
    const info = `*Data:*\n${prompts.join("\n")}`;
    const slackData = getSlackMessagePayload(false, info, prompts.length);
    await sendSlackMessage(slackData);
  };

  const cleanFileName = (prompt) => {
    return prompt
      .replace(/['"().,]/g, '') // remove quotes, parentheses, dots, commas
      .replace(/[^\w\s-]/g, '') // remove all other special characters except spaces and hyphens
      .replace(/\s+/g, '-') // replace spaces with hyphens
      .toLowerCase(); // convert to lowercase
  };
  
  const downloadImage = async (url, prompt) => {
    try {
      const blob = await compressImage(url, imageWidth, imageHeight, quality);
      const fileName = `${cleanFileName(prompt)}.jpeg`;
      
      const link = document.createElement('a');
      link.href = URL.createObjectURL(blob);
      link.download = fileName;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (err) {
      setError(`Error downloading image: ${err.message}`);
    }
  };
  
  const downloadAllAsZip = async () => {
    try {
      const zip = new JSZip();
      
      const downloads = generatedImages.map(async (url, index) => {
        if (!url) return null;
        const blob = await compressImage(url, imageWidth, imageHeight, quality);
        const fileName = `${cleanFileName(prompts[index])}.jpg`;
        zip.file(fileName, blob);
      });
  
      await Promise.all(downloads);
      
      const content = await zip.generateAsync({ type: 'blob' });
      const link = document.createElement('a');
      link.href = URL.createObjectURL(content);
      link.download = 'generated-images.zip';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (err) {
      setError(`Error creating zip file: ${err.message}`);
    }
  };  

  const handleAddPrompts = () => {
    if (!bulkPrompts.trim()) {
      setError("Please enter some prompts");
      return;
    }
    
    // Split by new lines and filter out empty lines
    const newPrompts = bulkPrompts
      .split('\n')
      .map(prompt => prompt.trim())
      .filter(prompt => prompt.length > 0);
    
    setPrompts(newPrompts);
    setGeneratedImages(new Array(newPrompts.length).fill(null));
    setLoading(new Array(newPrompts.length).fill(false));
    setBulkPrompts(''); // Clear the input area
  };

  const cleanAndAppendText = (existingText, newText) => {
    // If existing text is empty or only whitespace, just return new text
    if (!existingText || !existingText.trim()) {
      return newText;
    }
    
    // Remove only the last newline if it exists at the end
    const cleanedText = existingText.replace(/\n$/, '');
    
    // Combine with a single newline
    return `${cleanedText}\n${newText}`;
  };

  return (
    <div className="container">
      <h1 className="title">Image Generator</h1>
      
      {error && (
        <div className="error-message">
          {error}
        </div>
      )}

      <div className="input-section">
        <textarea
          value={bulkPrompts}
          onChange={(e) => setBulkPrompts(e.target.value)}
          placeholder="Enter your prompts (one per line)"
          className="bulk-input"
          rows={10}
        />
        <div className="flex justtify-between">
          <div className="button-group">
            <button onClick={handleAddPrompts} className="button">
              Add Prompts
            </button>
            <button
              onClick={generateAllImages}
              disabled={prompts.length === 0 || loading.some(Boolean)}
              className="button"
            >
              Generate All Images
            </button>
          </div>
          <div className="button-group">
            <AutoPromptPicker
              onCopy={(newPrompts) => {
                const cleanPrompts = cleanAndAppendText(
                  bulkPrompts,
                  newPrompts
                );
                setBulkPrompts(cleanPrompts);
              }}
            />
          </div>
        </div>
      </div>

      <div className="prompts-container">
        {prompts.map((prompt, index) => (
          <div key={index} className="prompt-card">
            <div className="prompt-text">
              <strong>Prompt {index + 1}:</strong> {prompt}
            </div>

            {generatedImages[index] && (
              <div className="image-container">
                <img
                  src={generatedImages[index]}
                  alt={prompt}
                  className="generated-image"
                />
                <div className="button-group">
                  <button
                    onClick={() => generateImage(prompt, index, true)} // (prompt, index, isRegenerateImage)
                    disabled={loading[index]}
                    className="button"
                  >
                    {loading[index] ? 'Generating...' : 'Regenerate'}
                  </button>
                  <button
                    onClick={() => downloadImage(generatedImages[index], prompt)}
                    className="button button-outline"
                  >
                    Download
                  </button>
                </div>
              </div>
            )}

            {loading[index] && (
              <div className="loading-container">
                <div className="loading-spinner"></div>
                <p>Generating image...</p>
              </div>
            )}
          </div>
        ))}
      </div>

      {generatedImages.some(Boolean) && (
        <div className="actions-container">
          <button onClick={downloadAllAsZip} className="button button-outline">
            Download All
          </button>
        </div>
      )}
    </div>
  );
};

export default Ideogram;