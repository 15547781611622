import React, { useEffect } from "react";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import { variables } from "../utils/variables";
import { verifyCookie } from "../utils/cookies";

const PrivateRoute = ({ element }) => {
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    // Verify cookie on route change
    const checkAuth = () => {
      if (!verifyCookie()) {
        Cookies.remove(variables.COOKIE_NAME);
        navigate("/login", {
          state: {
            from: location,
            message: "Your session has expired. Please login again.",
          },
          replace: true,
        });
      }
    };

    checkAuth();

    // Add event listener for cookie changes
    const handleStorageChange = (e) => {
      if (e.key === variables.COOKIE_NAME || e.key === null) {
        checkAuth();
      }
    };

    window.addEventListener("storage", handleStorageChange);

    return () => {
      window.removeEventListener("storage", handleStorageChange);
    };
  }, [location, navigate]);

  // Initial check
  if (!verifyCookie()) {
    return <Navigate to="/login" state={{ from: location }} replace />;
  }

  return element;
};

export default PrivateRoute;
