import CryptoJS from "crypto-js";
import { variables } from "./variables";

// Encryption helper functions
export const encryptData = (data) => {
  return CryptoJS.AES.encrypt(JSON.stringify(data), variables.SECRET_KEY).toString();
};

export const decryptData = (encryptedData) => {
  try {
    const bytes = CryptoJS.AES.decrypt(encryptedData, variables.SECRET_KEY);
    return JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
  } catch {
    return null;
  }
};

export const ENCRYPTED_CREDENTIALS = {
  username: CryptoJS.AES.encrypt("user", variables.SECRET_KEY).toString(),
  password: CryptoJS.AES.encrypt("password", variables.SECRET_KEY).toString(),
};