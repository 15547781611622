import dayjs from "dayjs";
import { getUserName } from "./auth";
import { perImageCharge } from "../constants";

export const getSlackMessagePayload = (
  isRegenerateImage = false,
  data,
  numberOfImages = 68
) => {
  const username = getUserName();

  // Format current date and time using dayjs
  const currentDateTime = dayjs().format("MMM D - h:mm A IST");

  return {
    text: `${isRegenerateImage ? "Regenerate" : "New"} API Request Ideogram`,
    blocks: [
      {
        type: "header",
        text: {
          type: "plain_text",
          text: `${isRegenerateImage ? "Regenerate" : "New"} Ideogram Request`,
          emoji: true,
        },
      },
      {
        type: "section",
        fields: [
          {
            type: "mrkdwn",
            text: `*Date Time:*\n${currentDateTime}`,
          },
          {
            type: "mrkdwn",
            text: `*Created by:*\n${username}`,
          },
        ],
      },
      {
        type: "section",
        fields: [
          {
            type: "mrkdwn",
            text: data,
          },
          {
            type: "mrkdwn",
            text: `*Cost:*\n$${
              isRegenerateImage
                ? perImageCharge
                : perImageCharge * numberOfImages
            }`,
          },
        ],
      },
    ],
  };
};
