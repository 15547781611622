export const REQUIRED_FILE_NAMES = [
    "50-passenger-charter-bus",
    "50-passenger-charter-bus-rental",
    "50-passenger-charter-bus-interior",
    "50-passenger-charter-bus-inside",
    "54-passenger-charter-bus",
    "54-passenger-charter-bus-rental",
    "54-passenger-charter-bus-interior",
    "54-passenger-charter-bus-inside",
    "55-passenger-charter-bus",
    "55-passenger-charter-bus-rental",
    "55-passenger-charter-bus-interior",
    "55-passenger-charter-bus-inside",
    "56-passenger-charter-bus",
    "56-passenger-charter-bus-rental",
    "56-passenger-charter-bus-interior",
    "56-passenger-charter-bus-inside",
    "15-passenger-minibus",
    "15-passenger-minibus-rental",
    "15-passenger-minibus-interior",
    "15-passenger-minibus-inside",
    "18-passenger-minibus",
    "18-passenger-minibus-rental",
    "18-passenger-minibus-interior",
    "18-passenger-minibus-inside",
    "20-passenger-minibus",
    "20-passenger-minibus-rental",
    "20-passenger-minibus-interior",
    "20-passenger-minibus-inside",
    "25-passenger-minibus",
    "25-passenger-minibus-rental",
    "25-passenger-minibus-interior",
    "25-passenger-minibus-inside",
    "28-passenger-minibus",
    "28-passenger-minibus-rental",
    "28-passenger-minibus-interior",
    "28-passenger-minibus-inside",
    "30-passenger-minibus",
    "30-passenger-minibus-rental",
    "30-passenger-minibus-interior",
    "30-passenger-minibus-inside",
    "35-passenger-minibus",
    "35-passenger-minibus-rental",
    "35-passenger-minibus-interior",
    "35-passenger-minibus-inside",
    "sprinter-van-with-driver",
    "sprinter-van-with-driver-rental",
    "sprinter-van-with-driver-interior",
    "sprinter-van-with-driver-inside",
    "school-bus-rental",
    "school-bus-rental-rental",
    "school-bus-rental-interior",
    "school-bus-rental-inside",
    "party-bus-rental",
    "party-bus-rental-rental",
    "party-bus-rental-interior",
    "party-bus-rental-inside",
    "sprinter-limo-rental",
    "sprinter-limo-rental-rental",
    "sprinter-limo-rental-interior",
    "sprinter-limo-rental-inside",
    "corporate-bus-rental",
    "wedding-transportation",
    "sporting-event-transportation",
    "school-event-transportation",
    "airport-shuttles",
    "private-event-transportation",
    "charter-bus",
    "56-passenger-motorcoach"
  ];
  