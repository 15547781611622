export const variables = {
  SECRET_KEY: process.env.REACT_APP_SECRET_KEY,
  ENCRYPTED_USERNAME_1: process.env.REACT_APP_ENCRYPTED_USERNAME_1,
  ENCRYPTED_PASSWORD_1: process.env.REACT_APP_ENCRYPTED_PASSWORD_1,
  ENCRYPTED_USERNAME_2: process.env.REACT_APP_ENCRYPTED_USERNAME_2,
  ENCRYPTED_PASSWORD_2: process.env.REACT_APP_ENCRYPTED_PASSWORD_2,
  ENCRYPTED_USERNAME_3: process.env.REACT_APP_ENCRYPTED_USERNAME_3,
  ENCRYPTED_PASSWORD_3: process.env.REACT_APP_ENCRYPTED_PASSWORD_3,
  ENCRYPTED_USERNAME_4: process.env.REACT_APP_ENCRYPTED_USERNAME_4,
  ENCRYPTED_PASSWORD_4: process.env.REACT_APP_ENCRYPTED_PASSWORD_4,
  ENCRYPTED_USERNAME_5: process.env.REACT_APP_ENCRYPTED_USERNAME_5,
  ENCRYPTED_PASSWORD_5: process.env.REACT_APP_ENCRYPTED_PASSWORD_5,
  ENCRYPTED_USERNAME_6: process.env.REACT_APP_ENCRYPTED_USERNAME_6,
  ENCRYPTED_PASSWORD_6: process.env.REACT_APP_ENCRYPTED_PASSWORD_6,
  COOKIE_NAME: "fuselioflow_auth_Token",
};
