import React, { useState } from "react";
import { Editor, EditorState, Modifier } from "draft-js";
import "draft-js/dist/Draft.css";

const Sitemap = () => {
  const [editorState, setEditorState] = useState(EditorState.createEmpty());

  const handlePaste = (text, editorState) => {
    // null/undefined text
    if (!text) return "";

    // Regular expression to match URLs
    const urlRegex = /(https?:\/\/[^\s]+)/g;
    const urls = text.match(urlRegex);

    if (!urls) {
      return text;
    }

    // Process URLs and add commas and new lines
    const processedText = urls.reduce((acc, url, index) => {
      if (index === urls.length - 1) {
        // Don't add comma and newline for the last URL
        return acc + url;
      }
      // Add comma and newline after each URL except the last one
      return acc + url + ",\n";
    }, "");

    return processedText;
  };

  const onChange = (newEditorState) => {
    setEditorState(newEditorState);
  };

  const handleBeforePaste = (text) => {
    const processedText = handlePaste(text, editorState);

    const contentState = editorState.getCurrentContent();
    const selection = editorState.getSelection();

    const newContentState = Modifier.replaceText(
      contentState,
      selection,
      processedText
    );

    const newEditorState = EditorState.push(
      editorState,
      newContentState,
      "insert-characters"
    );

    setEditorState(newEditorState);
    return "handled";
  };

  return (
    <div className="container">
      <h1 className="title">Sitemap</h1>
      <span>
        Download Sitemap Chrome Extension:{" "}
        <a
          href="https://chromewebstore.google.com/detail/xml-sitemap/olnnnhmebnbandgnhfaiggfjoklbmgab?hl=en"
          target="_blank"
          style={{ color: "red" }}
          rel="noopener noreferrer"
        >
          Link
        </a>
      </span>
      <div className="input-section mt-4">
        <div className="editor-wrapper">
          <Editor
            editorState={editorState}
            onChange={onChange}
            handlePastedText={(text) => handleBeforePaste(text)}
            placeholder="Paste URLs here..."
          />
        </div>
      </div>
    </div>
  );
};

export default Sitemap;
